@tailwind base;
@tailwind components;
@tailwind utilities;

a {
  @apply text-blue-700 hover:border-none;
}

a:hover {
  @apply border-b-2 border-blue-700;
}

.content p {
  @apply my-6;
}

.content ul {
  @apply my-6;
}

@layer base {
  * {
    --tw-backdrop-brightness: ;
    --tw-backdrop-blur: ;
    --will-be-deleted: ;
  }
}
@layer utilities {
  .card-hover {
    @apply hover:-translate-y-2 transition-all cursor-pointer lg:hover:shadow-lg;
  }
}

.badge {
  @apply text-3xs px-1.5 py-0 leading-3 font-bold h-3.5 backdrop-filter backdrop-blur-sm border-none;
}

.active-animation {
  background-image: linear-gradient(
      90deg,
      rgb(110 231 183) 50%,
      transparent 50%
    ),
    linear-gradient(90deg, rgb(110 231 183) 50%, transparent 50%),
    linear-gradient(0deg, rgb(110 231 183) 50%, transparent 50%),
    linear-gradient(0deg, rgb(110 231 183) 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 15px 8px, 15px 8px, 8px 15px, 8px 15px;
  background-position: left top, right bottom, left bottom, right top;
  animation: border-dance 1s infinite linear;
}

@keyframes border-dance {
  0% {
    background-position: left top, right bottom, left bottom, right top;
  }
  100% {
    background-position: left 15px top, right 15px bottom, left bottom 15px,
      right top 15px;
  }
}

.vertical-masonry-margin > a {
  display: block;
  margin-bottom: 20px;
}

.dotted-pattern {
  background-color: transparent;
  background-image: radial-gradient(#888cb0 1px, transparent 1px),
    radial-gradient(#888cb0 1px, transparent 1px);
  background-size: 20px 20px;
  background-position: 0 0, 10px 10px;
}

.perspective {
  perspective: 1000px;
}

.preserve-3d {
  transform-style: preserve-3d;
}
.perspective-rotate {
  transform: rotateY(25deg);
}
@media only screen and (max-width: 1010px) {
  .perspective-rotate {
    transform: rotateX(25deg);
  }
}

.rotate-n-push-z {
  transform: scale(1.1) translateZ(-100px) rotateY(25deg);
}

@keyframes animate-image {
  0% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

@layer utilities {
  .animate-image {
    animation: animate-image 4s infinite;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
}

@keyframes animate-fanous {
  0% {
    transform: rotate(-6deg);
  }

  50% {
    transform: rotate(6deg);
  }

  100% {
    transform: rotate(-6deg);
  }
}

@layer utilities {
  .animate-fanous {
    animation: animate-fanous 8s infinite;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    transform-origin: top left;
  }
}

.scroll-downs {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 30px auto 20px auto;

  width: 34px;
  height: 55px;
}
.mousey {
  margin: auto;
  width: 0px;
  padding: 10px 14px;
  height: 30px;
  border: 1px solid #fff;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
}
.scroller {
  width: 5px;
  height: 15px;
  border-radius: 44%;
  background-color: #fff;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;
  position: relative;
  right: 2px;
}

@keyframes scroll {
  0% {
    opacity: 0;
  }
  10% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(15px);
    opacity: 0;
  }
}

/* Logo Slider */

.logo-slider {
  background: white;
  -webkit-box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.125);
  box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.125);
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.logo-slider::before,
.logo-slider::after {
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
  content: '';
  height: 175px;
  position: absolute;
  width: 200px;
  z-index: 2;
}

.logo-slider::after {
  right: 0;
  top: 0;
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}

.logo-slider::before {
  left: 0;
  top: 0;
}

.logo-slider .logo-slide-track {
  -webkit-animation: logo-scroll 60s linear infinite;
  animation: logo-scroll 60s linear infinite;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: calc(235px * 14);
  animation-duration: 30s;
  animation-iteration-count: infinite;
}

.logo-slider .slide {
  height: 150px;
  width: 250px;
  padding: 20px;
}

@-webkit-keyframes logo-scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(calc(-250px * 7));
    transform: translateX(calc(-250px * 7));
  }
}

@keyframes logo-scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(calc(-250px * 7));
    transform: translateX(calc(-250px * 7));
  }
}

.login-box {
  position: relative;
  display: flex;
  justify-content: center;
}

.login-box .user-box {
  position: relative;
}

.login-box form a {
  position: relative;
  display: inline-block;
  overflow: hidden;
  transition: 0.5s;
}

.login-box a:hover {
  background: #dc5644;
  color: #fff;
  box-shadow: 0 0 0px #dc5644, 0 0 0px #dc5644, 0 0 0px #dc5644,
    0 0 30px #dc5644;
}

.login-box a span {
  position: absolute;
  display: block;
}

.login-box a span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #ffff);
  animation: btn-anim1 1s linear infinite;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

.login-box a span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #ffff);
  animation: btn-anim2 1s linear infinite;
  animation-delay: 0.25s;
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

.login-box a span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #ffff);
  animation: btn-anim3 1s linear infinite;
  animation-delay: 0.5s;
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

.login-box a span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #ffff);
  animation: btn-anim4 1s linear infinite;
  animation-delay: 0.75s;
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}

.neons {
  text-align: center;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 2s ease-in-out infinite alternate;
}

@keyframes glow {
     from {
      color: #fff;
    text-shadow: 0 0 5px #FCEDE4, 0 0 10px #FCEDE4, 0 0 20px #FCEDE4, 0 0 1px #FCEDE4, 0 0 1px #FCEDE4, 0 0 1px #FCEDE4, 0 0 1px #FCEDE4, 0 0 1px #FCEDE4;
  }
  
  to {
     color: white;
    text-shadow: 0 0 10px #FCEDE4, 0 0 20px #FCEDE4, 0 0 30px #FCEDE4, 0 0 40px #FCEDE4, 0 0 50px #FCEDE4, 0 0 60px #FCEDE4, 0 0 70px #FCEDE4, 0 1 90px #FCEDE4;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  25%, 75% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
}

.animate-fadeIn {
  animation: fadeIn 2s ease-in-out;
}

.animate-shake {
  display: inline-block;
  animation: shake 0.5s ease-in-out infinite;
  animation-delay: 2.5s; /* Start after fadeIn */
  animation-iteration-count: infinite;
  animation-play-state: paused;
}

.animate-shake:hover {
  animation-play-state: running;
}

.scroll-box {
  overflow-y: scroll;
}

.scroll-box::-webkit-scrollbar {
  width: 4px;
}

.scroll-box::-webkit-scrollbar-track {
  border-radius: 10px;
}

.scroll-box::-webkit-scrollbar-thumb {
  background: #afb2b4;
  border-radius: 10px;
}